body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 26px;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Jost", sans-serif;
  color: #232323;
}

ul {
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
}

/* mandatory area start */
.mandatory {
  background: #088395;
  padding: 35px 0px 0px;
  text-align: center;
  margin-top: -21px;
}

.mandatory p {
  margin: 0px;
  padding: 5px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

/* .mandatory a {
  padding: 5px 15px;
  background: #fe9d00;
  color: #fff;
  margin-left: 10px;
} */

.mandatory button {
  width: 150px;
  height: 50px;
  background: #00001a;
  border-style: none;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0px;
  overflow: hidden;
  transition: all .5s;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, .2);
}

.mandatory button span {
  position: absolute;
  display: block;
}

.mandatory button span:nth-child(1) {
  height: 3px;
  width: 200px;
  top: 0px;
  left: -200px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #fe9d00);
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span1 2s linear infinite;
  animation-delay: 1s;
}

@keyframes span1 {
  0% {
    left: -200px
  }

  100% {
    left: 200px;
  }
}

.mandatory button span:nth-child(2) {
  height: 70px;
  width: 3px;
  top: -70px;
  right: 0px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #fe9d00);
  border-bottom-left-radius: 1px;
  border-bottom-right-radius: 1px;
  animation: span2 2s linear infinite;
  animation-delay: 2s;
}

@keyframes span2 {
  0% {
    top: -70px;
  }

  100% {
    top: 70px;
  }
}

.mandatory button span:nth-child(3) {
  height: 3px;
  width: 200px;
  right: -200px;
  bottom: 0px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), #fe9d00);
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  animation: span3 2s linear infinite;
  animation-delay: 3s;
}

@keyframes span3 {
  0% {
    right: -200px;
  }

  100% {
    right: 200px;
  }
}

.mandatory button span:nth-child(4) {
  height: 70px;
  width: 3px;
  bottom: -70px;
  left: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), #099ad1);
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  animation: span4 2s linear infinite;
  animation-delay: 4s;
}

@keyframes span4 {
  0% {
    bottom: -70px;
  }

  100% {
    bottom: 70px;
  }
}

/* services section css */
.side-gap {
  padding: 0px 60px;
}

.service-details {
  margin-top: -129px;
  position: relative;
  z-index: 2;
}

.services-box {
  background-color: #071952;
  padding: 18px;
  transition: all 0.3s ease 0s;
  border-radius: 8px;
}

.services-box:hover,
.services-box.active {
  background: #37B7C3;
}

.services-box .services-inner {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.services-box .services-inner .icon {
  max-width: 70px;
  margin: 0px auto 20px;
  background: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 8px;
  box-shadow: 0px 1px 10px #000;
}

.services-box .services-inner .icon img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.services-box .services-inner .text h5 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0px;
}

.services-box .services-inner .text a {
  font-family: "Jost", sans-serif;
  color: #fff;
}

/* .services-box .services-inner .text p {
  margin-bottom: 20px;
  color: #fff;
  font-size: 14px;
  line-height: 25px;
}
.services-box .services-inner .text a {
  font-family: "Jost", sans-serif;
  color: #fff;
} */
.services-box .active {
  background: #fe9d00;
}

/* about-are css */
.about_first {
  width: 100%;
  height: 500px;
  border-radius: 30px;
  object-fit: cover;
}

.about_second {
  width: 100%;
  height: 300px;
  border-radius: 30px;
  /* object-fit: cover; */
}

.about-area {
  background-color: #eff7ff;
  padding: 90px 0px;
}

.about-title h5 {
  color: #088395;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.about-title h2 {
  font-size: 38px;
  margin-bottom: 0px;
  color: #232323;
  font-weight: 600;
  padding-bottom: 25px;
}

.about-title p {
  margin-bottom: 15px;
}

.content .text ul {
  padding-left: 20px;
}

.txt-clr {
  color: #245b73;
  font-size: 18px;
  line-height: 28px;
}

.about-content li {
  display: inherit;
  margin-top: 10px;
  width: 48%;
  float: left;
  margin-bottom: 0;
  color: #777;
}

.about-content li {
  display: flex;
  margin-right: 10px;
}

.about-content li:last-child {
  margin-right: 0px;
}

.about-content li .num {
  font-size: 20px;
  min-width: 56px;
  height: 56px;
  background: #fe9d00;
  text-align: center;
  margin-right: 15px;
  color: #fff;
  display: block;
  border-radius: 50%;
  font-weight: 500;
  line-height: 56px;
  font-family: "Jost", sans-serif;
}

.about-content li .text h3 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #00001a;
}

.mission-vision-area {
  position: relative;
}

.mission-vision-area::before {
  content: "";
  position: absolute;
  right: 0;
  top: 65px;
  height: 300px;
  width: 46.5%;
  /* z-index: -1; */
  background: #37B7C3;
  border-radius: 8px;
}

.mission-vision-area .image_1 img {
  width: 100%;
  position: relative;
  height: 300px;
  object-fit: cover;
  margin-top: 80px;
  border-radius: 8px;
}

.content .text h3 {
  margin-bottom: 20px;
}

.mission-vision-area .image_2 img {
  width: 100%;
  position: relative;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

/* faculties area css */

.faculties-div {
  display: flex;
  align-items: center;
  line-height: 20px;
}

.faculties-left {
  width: 35%;
}

.faculties-right {
  width: 65%;
}

.main-faculties {
  box-shadow: 0 2px 7px -3px #000;
  padding: 18px 25px;
  border-radius: 20px;
  margin-bottom: 30px;
}

.faculties-img img {
  width: 115px;
  margin-bottom: 10px;
  border-radius: 50%;
  height: 115px;
  box-shadow: 0 6px 9px -5px #00001a;
  border: 3px solid #088395;
  object-fit: cover;
}

/* Gallery area css */

.gallery-bg {
  padding: 70px 0px 70px 0px;
}

.main-gallery img {
  width: 100%;
  height: 300px;
  margin-bottom: 25px;
}

/* features area css */
.features-area {
  background-color: #071952;
  position: relative;
  padding: 70px 0px 70px 0px;
}

.features-gallery {
  background-color: #eff7ff;
  position: relative;
  padding: 70px 0px 70px 0px;
}

.features-gallery .owl-carousel .owl-item img {
  height: 292px;
}

.features-content h2 {
  font-size: 50px;
  padding-bottom: 0;
  margin-bottom: 0px;
  position: relative;
  color: #fff;
}

.features-content p {
  color: #96bece;
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.features-content ul {
  margin-top: 40px;
}

.features-content ul li {
  margin-bottom: 30px;
  margin-top: 0;
  list-style: none;
}

.step-box {
  display: flex;
}

.step-box .icon-box {
  min-width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #fe9d00;
  margin-right: 20px;
  text-align: center;
  position: relative;
  float: left;
  color: #fff;
  font-family: "Jost", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 70px;
}

.step-box .icon-box img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.step-box .text h3 {
  font-size: 30px;
  margin-bottom: 5px;
  color: #fff;
  font-weight: 600;
}

.step-box .text p {
  margin-bottom: 0;
  color: #96bece;
  margin-top: 0px;
  font-size: 15px;
}

.features-img img {
  max-width: 100%;
  border: none;
  box-shadow: none;
  height: 224px;
  width: 224px;
  border-radius: 50%;
  display: table;
  margin: auto;
}

.director-img img {
  height: 300px;
  width: 300px;
  object-fit: cover;
  border: 3px solid #37B7C3;
  padding: 5px;
}

.animations-10 {
  position: absolute;
  left: 0;
  bottom: 110px;
  animation: alltuchtopdown 3s infinite;
  -webkit-animation: alltuchtopdown 3s infinite;
  animation-delay: 0s;
  -webkit-animation-delay: 1s;
}

.animations-10 img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

@keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

@-webkit-keyframes alltuchtopdown {
  0% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }

  50% {
    -webkit-transform: rotateX(0deg) translateY(-20px);
    -moz-transform: rotateX(0deg) translateY(-20px);
    -ms-transform: rotateX(0deg) translateY(-20px);
    -o-transform: rotateX(0deg) translateY(-20px);
    transform: rotateX(0deg) translateY(-20px);
  }

  100% {
    -webkit-transform: rotateX(0deg) translateY(0px);
    -moz-transform: rotateX(0deg) translateY(0px);
    -ms-transform: rotateX(0deg) translateY(0px);
    -o-transform: rotateX(0deg) translateY(0px);
    transform: rotateX(0deg) translateY(0px);
  }
}

/* contact area start */
.contact-area {
  padding: 110px 0px;
}

.contact-bg {
  background: #071952;
  padding: 50px 50px;
  border-radius: 10px;
}

.contact-bg h2 {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
}

.contact-bg .form-control {
  width: 100%;
  padding: 13px 13px;
  font-size: 15px;
  background-color: #fff;
  color: #000;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  font-weight: 500;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 32px;
}

.contact-bg .form-control:focus {
  outline: none !important;
  border-color: #fe9d00;
  box-shadow: none !important;
}

/* breadcrumb area css */
.breadcrumb-area {
  position: relative;
  background-size: cover;
  min-height: 230px;
  background-repeat: no-repeat;
  /* background-color: #20282e; */
  background-image: url("./images/bg.jpg");
  background-position: center;
}

.breadcrumb-title h2 {
  font-size: 50px;
  margin-bottom: 25px;
  line-height: 1;
  color: #000;
  /* letter-spacing: 1px; */
  text-shadow: 3px 1px 0px #37B7C3;
}

.breadcrumb-wrap2 {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  bottom: -25px;
  z-index: 1;
}

.breadcrumb {
  display: inline-block;
  -ms-flex-wrap: wrap;
  flex-wrap: unset;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  border-radius: 30px;
  background: #088395;
  padding: 15px 35px;
  position: relative;
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb li a {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.breadcrumb>.active {
  color: #fffc !important;
  font-weight: 500;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 15px;
  padding-left: 10px;
  color: #ddd;
  content: "|";
}

/* governing page css */

.governing-body {
  padding: 80px 0px 60px;
}

.governing-img img {
  width: 100%;
  margin-bottom: 5px;
  height: 306px;
  /* object-fit: cover; */
}

.governing-main {
  border: 3px solid #a6a6a6;
  padding: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.governing-main h4 {
  font-size: 21px;
  font-weight: 600;
  color: rgb(0 0 26);
  margin-bottom: 2px;
}

.governing-main p {
  font-size: 17px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 0px;
}

/* Principal sec css */

.Principal_sec {
  background-color: #fff;
}

.Principal_sec .features-content p {
  color: #00001a;
  font-size: 17px;
}

.Principal_sec .features-img img {
  height: 250px;
  width: 250px;
  object-fit: cover;
  border: 4px solid #088395;
}

.Principal_sec .features-img h4 {
  font-weight: 600;
  margin: 8px 0px 3px;
}

.Principal_sec .features-img p {
  margin-bottom: 0px;
  font-size: 17px;
}

/* Courses Offered */
.courses-offered table {
  width: 100%;
}

.courses-offered table td {
  padding: 0.5em 0.625em;
  border-top: 1px solid #ddd;
}

/*  */

.prospectus-structure {
  padding: 80px 0px 80px;
}

.prospectus-structure canvas {
  width: 100% !important;
  height: 1430px !important;
}

.react-pdf__Page__textContent {
  width: 100% !important;
}

.react-pdf__Page__annotations {
  width: 100% !important;
}

.pdf-view button {
  background: #fe9d00;
  border: none;
  color: #fff;
  padding: 3px 12px;
  border-radius: 2px;
  font-weight: 600;
  margin-right: 5px;
}

/* Contact us page area css */

.contact-page-bg {
  padding: 110px 0px 80px;
  background-color: #e7f0f8;
}

.con-map iframe {
  width: 100%;
  height: 450px;
}

.contact-icon {
  max-width: 35px;
  display: flex;
  /* align-items: center; */
  margin-right: 20px;
}

.contact-icon svg {
  color: #088395;
  font-size: 25px;
}

.contact-dec h3 {
  font-size: 16px;
  margin-bottom: 0;
}

.contact-form h2 {
  font-size: 22px;
  margin-bottom: 25px;
  font-weight: 600;
}

.contact-form .form-control {
  width: 100%;
  padding: 13px 13px;
  font-size: 15px;
  background-color: #fff;
  color: #000;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  font-weight: 500;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 0px;
}

.contact-form .form-control:focus {
  outline: none !important;
  border-color: #fe9d00;
  box-shadow: none !important;
}

/* awards and achievements area */
.affiliation-bg {
  padding: 80px 0px;
}

.awards_main {
  position: relative;
  transition: all 0.3s ease 0s;
  background-color: #f0f7ff;
  border-radius: 4px;
  margin-bottom: 30px;
}

.awards_main:hover {
  box-shadow: 0px 6px 9px 2px rgba(0, 0, 0, 0.1);
}

.awards_img {
  overflow: hidden;
}

.awards_img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}

.awards_main:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.awards_content {
  padding: 30px;
  position: relative;
}

.awards_content .sub {
  border-radius: 4px;
  background-color: #fe9d00;
  position: absolute;
  top: -18px;
  right: 30px;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.awards_content .sub svg {
  margin-right: 5px;
}

.slider-items .owl-nav {
  position: absolute;
  top: -100px;
  right: 0px;
}

.slider-items button.owl-prev {
  background: #37B7C3 !important;
  width: 50px;
  height: 50px;
  font-size: 35px !important;
  color: #fff !important;
  margin-right: 10px;
  border-radius: 4px;
}

.slider-items button.owl-next {
  background: #37B7C3 !important;
  width: 50px;
  height: 50px;
  font-size: 35px !important;
  color: #fff !important;
  border-radius: 4px;
}

.partner-area {
  padding: 60px 0px 50px;
}

.partner_img img {
  object-fit: contain;
  aspect-ratio: 4 / 2;
}

/* notice area css */
/*--------notice start-------*/

.marquee {
  overflow: hidden;
}

.marquee ul {
  animation: marquee 20s linear infinite;
}

.marquee.running ul {
  animation-play-state: running;
}

.marquee ul:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.notice_title {
  background-color: #071952;
  text-align: center;
  padding: 15px 0px;
  position: relative;
  display: flex;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.notice_title h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
  background: #37B7C3;
  padding: 15px 20px;
  margin-top: -40px;
  border-radius: 10px;
}

.notice_list {
  padding: 14px 15px;
  text-align: center;
  background: #071952;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 612px;
  /* overflow: hidden; */
  overflow-x: auto;
}

.notice_list marquee {
  height: 284px;
}

.notice_list ul li {
  background-color: #37B7C3;
  text-align: left;
  padding: 20px 17px;
  margin: 4px 0px;
  overflow: hidden;
  list-style: none;
}

.notice_list ul li a {
  color: #fff;
  font-size: 17px;
}

tr.bold {
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

/*--------notice end---------*/
/* infrastructure area start */

.infrastructure-bg {
  padding: 70px 0px;
}

.infrastructure-bg img {
  width: 100%;
}

.infrastructure-content h3 {
  position: relative;
  display: block;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #232323;
}

.infrastructure-content p {
  position: relative;
  display: block;
  margin-bottom: 20px;
  color: #6e6e6e;
  font-size: 17px;
}

.infrastructure-content ul {
  margin: 0 0 1.313em 1.655em;
}

/* .infrastructure-right-bar {
  position: relative;
  height: 100%;
} */
.infrastructure-links {
  position: sticky;
  top: 0px;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 5px 8.66px 58px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #e3e3e3;
}

.infrastructure-links h3 {
  background: #125875;
  color: #fff;
  font-size: 20px;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 0px;
}

.infrastructure-links ul li {
  list-style: none;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 15px;
}

@media (max-width: 1680px) {
  /* .features-img {
    margin-right: -170px;
  } */
}

@media (max-width: 1440px) {

  /* .features-img {
    margin-right: -55px;
  } */
  .breadcrumb-area {
    min-height: 350px;
  }
}

@media (max-width: 1366px) {
  .features-area {
    padding: 60px 0px 25px;
  }

  .features-img {
    margin-right: 0px;
  }

  .about-img img {
    width: 100%;
  }

  .breadcrumb-area {
    min-height: 330px;
  }
}

@media (max-width: 1200px) {
  .side-gap {
    padding: 0px 30px;
  }
}

@media (max-width: 1199px) {
  .contact-bg {
    padding: 50px 30px;
  }
}

@media (max-width: 1024px) {
  .side-gap {
    padding: 0px 10px;
  }
}

@media (max-width: 992px) {
  .mission-vision-area::before {
    display: none;
  }

  .mission-vision-area .image_1 img {
    margin-top: 0px;
  }

  .content .text ul {
    margin-bottom: 35px;
  }

  .message-reverse {
    flex-direction: column-reverse;
  }

  .infrastructure-bg img {
    margin-bottom: 25px;
  }

  .features-content {
    text-align: center;
  }

  .service-details {
    margin-top: 0px;
    padding: 40px 0px 40px;
  }

  .banner .carousel-control-prev {
    left: 84%;
  }

  .about-title h2 {
    font-size: 41px;
  }

  .features-img img {
    margin: 20px auto 40px;
  }

  .notice_list {
    margin-bottom: 45px;
  }

  .about-img img {
    margin-bottom: 20px;
  }

  .breadcrumb-area {
    min-height: 250px;
  }

  .breadcrumb-title h2 {
    font-size: 57px;
  }

  .infrastructure-links {
    margin-top: 20px;
  }

  .contact-form {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .breadcrumb-area {
    min-height: 200px;
  }

  .mission-vision-area .image_1 img {
    margin-bottom: 25px;
  }

  .breadcrumb-title h2 {
    font-size: 42px;
  }

  .mandatory {
    padding: 10px 0px 0px;
  }
}

@media (max-width: 767px) {
  .about-title h5 {
    margin-bottom: 5px;
  }

  .about-title h2 {
    font-size: 28px;
  }

  .about-title h2 {
    font-size: 28px;
  }

  .content {
    display: block;
  }

  .about-content li .num {
    max-width: 56px;
    margin-bottom: 13px;
  }

  .about-content li .text h3 {
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .side-gap {
    padding: 0px;
  }
}

@media (max-width: 430px) {
  .about_first {
    height: auto;
    margin-bottom: 15px;
  }

  .services-box {
    margin-bottom: 15px;
  }

  .content .text ul {
    padding-left: 20px;
  }

  .main-gallery img {
    height: auto;
  }

  .about_first {
    height: auto;
  }

  .about_second {
    height: auto;
  }

  .institute_name h3 {
    font-size: 25px;
  }

  .features-img img {
    height: 180px;
    width: 180px;
  }

  .services-box .services-inner .text h5 {
    font-size: 16px;
  }

  .slider-items button.owl-prev {
    width: 35px;
    height: 35px;
  }

  .slider-items button.owl-next {
    width: 35px;
    height: 35px;
  }

  .about-area {
    padding: 50px 0px 35px;
  }

  .affiliation-bg {
    padding: 50px 0px 10px;
  }

  .slider-items .owl-nav {
    top: -90px;
  }

  .notice_list {
    height: 350px;
  }

  .features-area {
    padding: 50px 0px 60px;
  }

  .features-content h2 {
    font-size: 32px;
  }

  .step-box .text h3 {
    font-size: 24px;
  }

  .contact-area {
    padding: 80px 0px 60px;
  }

  .main-footer {
    padding-top: 45px;
  }

  .about-content li .text h3 {
    font-size: 21px;
  }

  .contact-bg h2 {
    font-size: 32px;
  }

  .about-content li {
    width: 100%;
    margin-right: 0px;
  }

  .about-content li:first-child {
    margin-right: 10px;
  }

  .breadcrumb-area {
    min-height: 115px;
  }

  .breadcrumb-title h2 {
    font-size: 29px;
  }

  .breadcrumb-wrap2 {
    bottom: -18px;
  }

  .breadcrumb {
    padding: 8px 30px;
  }

  .infrastructure-content h3 {
    font-size: 23px;
  }

  .infrastructure-content p {
    font-size: 15px;
  }

  .infrastructure-bg {
    padding: 45px 0px 40px;
  }

  .contact-page-bg {
    padding: 50px 0px 40px;
  }

  .about_first {
    margin-bottom: 20px;
  }

  .about_second {
    margin-bottom: 30px;
  }

  .txt-clr {
    font-size: 16px;
    line-height: 25px;
  }

  .features-content p {
    font-size: 16px;
  }
}

@media (max-width: 414px) {
  .mandatory p {
    font-size: 16px;
  }

  .contact-bg {
    padding: 30px 20px;
  }

  .services-box {
    padding: 18px 8px;
  }
}

@media (max-width: 375px) {
  .services-box {
    padding: 14px;
  }

  .about-title h2 {
    font-size: 25px;
  }

  .features-content h2 {
    font-size: 28px;
  }

  .step-box .text h3 {
    font-size: 22px;
  }

  .contact-bg h2 {
    font-size: 28px;
  }

  .step-box .icon-box {
    min-width: 62px;
    height: 62px;
    font-size: 16px;
    line-height: 53px;
  }
}

@media (max-width: 360px) {
  .about-content li .text h3 {
    font-size: 19px;
  }

  .services-box {
    padding: 14px 2px;
  }

  .mandatory p {
    font-size: 14px;
  }

  .infrastructure-content h3 {
    font-size: 22px;
  }
}