/* Main Footer */

.main-footer {
  position: relative;
  padding-top: 80px;
  background-color: #071952;
  background-size: cover;
  /* background-image: url("https://wpdemo.zcubethemes.com/qeducato/wp-content/uploads/2023/03/footer-bg.png"); */
}

.main-footer .widgets-section {
  position: relative;
  padding: 0px 74px 30px;
}

.bank-content p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.bank-content p span {
  color: #b2c0d8;
  font-weight: 400;
}

.main-footer .widget {
  margin-bottom: 40px;
}

.main-footer .widget .widget-title {
  color: #fff;
}

/* Widget */
.widget {
  position: relative;
  margin-bottom: 50px;
}

.widget .widget-title {
  position: relative;
  font-size: 24px;
  color: #222;
  padding-bottom: 16px;
  margin-bottom: 35px;
  font-weight: 600;
}

.widget .widget-title:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 7px;
  height: 1px;
  width: 70px;
  background: #37B7C3;
}

.about-widget .text {
  position: relative;
  color: rgb(255 255 255 / 0.6);
  margin-bottom: 30px;
  font-weight: 400;
}

.about-widget .text a {
  color: #fff;
}

.about-widget .social-links {
  position: relative;
  margin-bottom: 20px;
}

.about-widget .social-links li {
  position: relative;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  display: inline-block;
}

.about-widget .social-links li a {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 14px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #37B7C3;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about-widget .social-links li a svg {
  position: relative;
}

.about-widget .social-links li a:hover {
  color: #ffffff;
}

.about-widget .social-links li a:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.about-widget .social-links li a:hover:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.about-widget .social-links li a:before {
  background: #088395;
}

.about-widget .social-links li a span {
  position: relative;
}

.newsletter-widget .text {
  position: relative;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 30px;
}

.newsletter-widget .form-group {
  position: relative;
}

.newsletter-widget input {
  position: relative;
  height: 56px;
  padding: 0 20px;
  background-color: #2c2c2c;
  width: calc(100% - 56px);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.5);
  border: none;
}

.newsletter-widget input:focus-visible {
  outline: none;
}

.newsletter-widget .theme-btn {
  width: 56px;
  height: 56px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  line-height: 56px;
  color: #fff;
  border-radius: 2px;
  transition: 0.5s;
  border: none;
}

.newsletter-widget .theme-btn:hover {
  background-color: #fff;
}

.links-widget ul li {
  color: #fff;
  margin-bottom: 10px !important;
  list-style: none;
}

.newsletter-widget .theme-btn {
  background-color: #6c5ebf;
}

.links-widget ul li a {
  color: #b2c0d8;
  transition: 0.5s;
  font-weight: 400;
  position: relative;
  display: inline-block;
  font-size: 16px;
}

.links-widget ul li a:hover {
  padding-left: 10px;
}

.links-widget ul li a:hover:after {
  opacity: 1;
}

.contact-widget .contact-info {
  position: relative;
}

.contact-widget .contact-info li {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
}

.map iframe {
  height: 290px;
}

.contact-widget .contact-info li .icon {
  position: relative;
  display: block;
  color: #ffffff;
  font-size: 14px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background: #37B7C3;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 500ms ease;
  margin: 8px 0;
  margin-right: 15px;
  font-size: 18px;
}

.contact-widget .contact-info li:hover .icon {
  background-color: #088395;
}

.contact-widget .contact-info li .text {
  position: relative;
  color: rgb(255 255 255 / 0.6);
  width: calc(100% - 60px);
  font-weight: 400;
}

.contact-widget .contact-info li .text a {
  color: rgb(255 255 255 / 0.6);
  transition: 0.5s;
}

.footer-bottom .wrapper-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer-bottom .wrapper-box .text a {
  color: #37B7C3;
  font-weight: bold;
}

.footer-bottom {
  border-top: 1px solid rgba(18, 88, 117, 0.85);
  padding: 10px 0;
}

.footer-bottom .text {
  position: relative;
  margin-bottom: 0;
  color: #ccc;
}

.footer-bottom .footer-menu li {
  display: inline-block;
  margin-right: 10px;
  padding-right: 13px;
  border-right: 1px solid #fff;
  line-height: 18px;
}

.footer-bottom .footer-menu li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: 0;
}

.footer-bottom .footer-menu li a {
  color: #fff;
  transition: 0.5s;
}

.footer-links {
  display: flex;
}

.footer-links ul:first-child {
  width: 35%;
  border-right: 1px solid rgba(18, 88, 117, 0.85);
}

.footer-links ul:last-child {
  padding-left: 30px;
  width: 65%;
}

/* fixed social */

#fixed-social {
  position: fixed;
  top: 250px;
  left: 15px;
  z-index: 2;
}

#fixed-social a {
  color: #fff;
  display: block;
  height: 40px;
  position: relative;
  text-align: center;
  line-height: 40px;
  width: 40px;
  margin-bottom: 1px;
  z-index: 2;
}

#fixed-social a:hover>span {
  visibility: visible;
  left: 41px;
  opacity: 1;
}

#fixed-social a span {
  line-height: 40px;
  left: 60px;
  position: absolute;
  text-align: center;
  width: 120px;
  visibility: hidden;
  transition-duration: 0.5s;
  z-index: 1;
  opacity: 0;
}

.fixed-facebook {
  background-color: #3b5998;
}

.fixed-facebook span {
  background-color: #3b5998;
}

.fixed-instagram {
  background-color: #f53641;

}

.fixed-instagram span {
  background-color: #f53641;
}

.fixed-wp {
  background-color: #045D04;

}

.fixed-wp span {
  background-color: #045D04;
}


@media (max-width: 430px) {
  .footer-bottom .wrapper-box {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}